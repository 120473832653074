import { Injectable } from '@angular/core';
import {UserService} from '../User/user.service';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from "../../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {
   appUrl = environment.appUrl + environment.whatsappOfficialservice;
  //   openAPIs = ['https://wa.chatmybot.in/gateway/nbcc/v1/api/trans/save','http://62.171.169.159/gateway/wabuissness/v1/api/countrywisepricelist/getall','http://62.171.169.159/gateway/wabuissness/v1/api/clientsignup/sentextsms?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a','http://62.171.169.159/gateway/wabuissness/v1/api/clientsignup/getclient?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a','http://62.171.169.159/gateway/wabuissness/v1/api/clientsignup/insert?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a']
// ,'https://wa.chatmybot.in/gateway/digimozo/v1/api/registationmaster/get','https://wa.chatmybot.in/gateway/digimozo/v1/api/registraiton/save','https://wa.chatmybot.in/gateway/digimozo/v1/api/registraiton/sendOtp'
//   openAPIs = ['https://wa.chatmybot.in/gateway/nbcc/v1/api/trans/save','https://wa.chatmybot.in/gateway/wabuissness/v1/api/countrywisepricelist/getall','http://62.171.169.159/gateway/wabuissness/v1/api/countrywisepricelist/getall','http://62.171.169.159/gateway/wabuissness/v1/api/clientsignup/sentextsms?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a','http://62.171.169.159/gateway/wabuissness/v1/api/clientsignup/getclient?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a','http://62.171.169.159/gateway/wabuissness/v1/api/clientsignup/insert?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a','http://62.171.169.159/gateway/auth/v1/oauth/token']
  openAPIs = [
    'https://wa.chatmybot.in/gateway/nbcc/v1/api/trans/save',this.appUrl + 'api/countrywisepricelist/getall',
    this.appUrl + 'api/clientsignup/sentextsms?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a',
    this.appUrl + 'api/clientsignup/getclient?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a',
    this.appUrl + 'api/clientsignup/insert?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a',
    this.appUrl + 'api/embeddedsignup/?accessToken=0d043666-e993-471a-9fd1-ce1799db9d8a',
    'https://wa.chatmybot.in/gateway/auth/v1/oauth/token','https://connect.facebook.net/en_US/sdk.js',
     "https://wa.chatmybot.in/gateway/wabuissness/v1/thirdparty/accountinfo", 
     "https://wa.chatmybot.in/gateway/wabuissness/v1/campaign/bulkdataupload", 
     "https://wa.chatmybot.in/gateway/wabuissness/v1/joho/getjohotemplateinfo", 
    environment.appUrl+ environment.whatsappOfficialservice +"digimongo/verifyNumberViaOtp",  
    environment.appUrl+ environment.whatsappOfficialservice +"digimongo/checkbyphonenumber", 
    environment.appUrl+ environment.whatsappOfficialservice +"digimongo/webpage", 
    environment.appUrl+ environment.whatsappOfficialservice +"digimongo/paymentOtpVerify", 
    "https://wa.chatmybot.in/chatbotdynamicmaven/api/v1/bot/generatekeypost", "http://62.171.169.159/chatbotdynamicmaven/web/sendOtp", 
    "http://62.171.169.159/chatbotdynamicmaven/web/checkbyphonenumber",
    "http://62.171.169.159/gateway/wabuissness/v1/johochat/chatbotconversion"
  ]
  constructor(public auth: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // tslint:disable-next-line:triple-equals
    if (request.headers.keys().length == 0)
    {

      if (!this.openAPIs.includes(request.url)){
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this.auth.getAccessToken(),
            'Content-Type': 'application/json'

          }
        });
      }else{
        console.log("it;s  open page")
      }

    }
    return next.handle(request).pipe( tap(() => {


      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          // tslint:disable-next-line:triple-equals
          if (err.status == 401) {
            console.log('Failed To Proccess So Will Logout as 401');
            this.auth.logout();
            return;
          }

        }
      }));

  }
}
