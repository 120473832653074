import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ConversationDao} from '../ConversationDao';
import {ConversationService} from '../ConversationService/conversation.service';
import {AlertMessageService} from '../../alert-message/alert-message.service';
import {ConversationSubscriptionDao} from '../ConversationService/ConversationSubscriptionDao';

@Injectable({
  providedIn: 'root'
})
export class ConversationSubscriptionService {
  private dataObsevable: BehaviorSubject<ConversationSubscriptionDao> = new BehaviorSubject(null);
  lastMessageTime:number=Number(localStorage.getItem("LASTINCOMINGMESSAGE"));

  constructor(private coversationService:ConversationService,private alertMessage:AlertMessageService) {
  //   this.getData();
  // var current=this;
  // setInterval(function(){
  //   current.getData();
  //   },2500)
    this.startPolling();
  }

  startPolling() {
    const poll = async () => {
      try {
        const result = await this.getData(); // Call getData and wait for it to complete
        // @ts-ignore
        if (result) {
          console.log('Polling successful, next poll in 3 seconds...');
          setTimeout(poll, 3000); // Continue polling every 3 seconds
        } else {
          console.error('Error in getData: Returning false, retrying in 1 minute...');
          setTimeout(poll, 1 * 60 * 1000); // Retry in 1 minute
        }
      } catch (error) {
        console.error('Error in getData:', error);
        console.log('Retrying in 1 minute due to an error...');
        setTimeout(poll, 1 * 60 * 1000); // Retry in 1 minute if there's an error
      }
    };
    poll(); // Start the polling process
  }

  refreshChatAgain()
  {
    this.getData();
  }
  getData()
  {
    this.coversationService.getConversationUnread().subscribe(value => {

      let tempObj:ConversationSubscriptionDao={data: value, isNew: false,lastMessageTime:this.lastMessageTime}
      if(value.length>0&&value[0].lastMessageTime>this.lastMessageTime)
      {
        tempObj.isNew=true;
      this.lastMessageTime=value[0].lastMessageTime;
      localStorage.setItem("LASTINCOMINGMESSAGE",String(this.lastMessageTime));
      this.alertMessage.playIncomingMessageSound();
      }
      this.dataObsevable.next(tempObj);
      tempObj=null;
      return true;
    }, error => {
      return false;
    })
  }

 getObj(): Observable<ConversationSubscriptionDao>
 {
   return this.dataObsevable.asObservable();
 }

}
